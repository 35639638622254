import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import mark from '../img/logo/mark.png';
import cl from 'classnames';

const IndexPage = ({ pageContext }) => {
  const [pic, setPic] = useState(null);
  useEffect(() => {
    setPic(Math.floor(Math.random() * 6) + 1);
  }, []);
  return <Layout pageContext={pageContext}>
    <SEO title="СК Мастер" />
    <section id="home" className={cl('hero hero_full_screen hero_parallax text-center  parallax-window', pic && `_${pic}`  )}>

      <div className="bg-overlay opacity-6">
      </div>

      <div className="hero_parallax_inner">
        <div className="container">
          <h3>Строительство – это просто. Для вас. Ведь вам ничего не нужно делать – просто закажите нужные
      вам услуги в строительной компании «Мастер».</h3>
          <h1>Строительство – это ответственно. Для нас.</h1>
          <h3>Потому что «Мастер» сделает все, чтобы вы получили идеальное строение, независимо от того, что
      вам потребуется.</h3>
          <Link className="btn btn-default aisle-btn" to="/projects/">Проекты</Link>
        </div>
      </div>

    </section>


    <div id="about" className="pt100 pb80">
      <div className="container">
        <div className="row">
          <div className="section-title text-center col-md-12 col-sm-12">
            <h2>Компания "СК Мастер"</h2>
            <p>История ООО «СК МАСТЕР» начинается с 2013 года. За это время мы успешно реализовали множество проектов по всей Московской области (поселки Бристоль, Кембридж, Гринвуд, Марсель и др.). Ознакомиться с нашим портфолио Вы всегда можете в соответствующем разделе сайта. Большой опыт работы, профессиональные строители, самые надежные материалы от проверенных поставщиков и современное оборудование – все это позволяет нам оказывать услуги класса «премиум». </p>
            <h3>Что мы предлагаем</h3>
            <p>Обращаясь в компанию «СК МАСТЕР», Вы получаете возможность решить сразу несколько задач. Перечень наших услуг включает в себя:</p>
          </div>
        </div>
        <div className="row">
          <div className="section col-md-12 col-sm-12">
            <ul className="list-group checklist">
              <li className="list-group-item"> строительство малоэтажных объектов любого назначения в Москве и Московской области (включая земляные работы, устройство фундаментов и перекрытий, кровельные и фасадные работы, монтаж ограждений, ворот и др.);</li>
              <li>монтажные, пусконаладочные работы и обслуживание инженерных сетей (внешних и внутренних);</li>
              <li>создание ландшафта и благоустройство территории;</li>
              <li>отделочные работы (черновые и финишные);</li>
              <li>изготовление интерьерных лестниц на заказ;</li>
              <li>поставка качественных строительных и отделочных материалов.</li>
            </ul>
            <p>У нас есть собственный отдел снабжения, производственно-технический и конструкторские отделы. </p>
            <p className='exclamation'>Работая с «СК МАСТЕР», Вы поймете, что качество – в мелочах!</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="icon-box icon-box-default">
              <img src={mark} />
              <h4>Надежность.</h4>
              <p>ООО «СК МАСТЕР» - официальная компания, поэтому мы даем все  необходимые гарантии и строго соблюдаем условия договоров. Кроме того, у нас есть сертификаты и допуски.</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="icon-box icon-box-default">
            <img src={mark} />
              <h4>Качество.</h4>
              <p>Наш штат – это опытные профессионалы по строительству и отделке, использующие самое современное оборудование и качественные материалы от проверенных производителей и поставщиков. Мы соблюдаем все актуальные ГОСТы и СНиПы.</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="icon-box icon-box-default">
            <img src={mark} />
              <h4>Доступные цены.</h4>
              <p>Мы имеем налаженные связи с зарекомендовавшими себя производителями и поставщиками материалов, а также грамотно подходим к логистике и внутренним процессам.</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="icon-box icon-box-default">
            <img src={mark} />
              <h4>Соблюдение сроков.</h4>
              <p>Мы дорожим не только своим, но и Вашим временем.</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="text-center col-md-12 col-sm-12">
            <p>Получить более подробную информацию Вы можете, обратившись к нашим менеджерам любым удобным способом.</p>
            <p className='exclamation'>«СК МАСТЕР» - ответственное строительство!</p>
          </div>
        </div>

      </div>
    </div>

  </Layout>
}

export default IndexPage
